import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/pawel/Projekty/wyspiewana-tradycja/src/layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Deklaracja dostępności strony internetowej Wyśpiewana tradycja`}</h1>
    <h2>{`Oświadczenie dotyczące dostępności`}</h2>
    <p>{`Gminny Ośrodek Kultury Gminy Psary zobowiązuje się zapewnić dostępność strony internetowej www.zespolyspiewacze.pl zgodnie z ustawą z dnia 4 kwietnia 2019 r. o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych.`}</p>
    <ul>
      <li parentName="ul">{`Data publikacji strony internetowej: 23.10.2020`}</li>
      <li parentName="ul">{`Data ostatniej istotnej aktualizacji: 15.11.2020`}</li>
    </ul>
    <h2>{`Udogodnienia`}</h2>
    <p>{`Strona posiada następujące ułatwienia dla osób z niepełnosprawnościami:`}</p>
    <ul>
      <li parentName="ul">{`wygodna nawigacja ułatwiająca przeglądanie treści poprzez osoby niedowidzące i niesłyszące,`}</li>
      <li parentName="ul">{`możliwość obsługi serwisu przy pomocy samej klawiatury jak i myszki,`}</li>
      <li parentName="ul">{`zastosowano prawidłowy kontrast,`}</li>
      <li parentName="ul">{`widoczny fokus,`}</li>
      <li parentName="ul">{`opisy alternatywne zdjęć i grafik,`}</li>
      <li parentName="ul">{`napisy do filmów,`}</li>
      <li parentName="ul">{`filmy z audiodeskrypcją i napisami,`}</li>
      <li parentName="ul">{`spójna i logiczna nawigacja ułatwiająca osobom niewidomym korzystanie z urządzeń czytających,`}</li>
      <li parentName="ul">{`wersja audio artykułów o zespołach.`}</li>
    </ul>
    <h2>{`Status pod względem zgodności`}</h2>
    <p>{`Strona internetowa jest częściowo zgodna z ustawą o dostępności cyfrowej stron internetowych i aplikacji mobilnych podmiotów publicznych z powodu niezgodności lub wyłączeń wymienionych poniżej:`}</p>
    <ul>
      <li parentName="ul">{`filmy „Kokocyna” oraz „Koło Gospodyń Wiejskich z Góry Siewierskiej” nie posiadają audiodeskrypcji.`}</li>
    </ul>
    <h2>{`Przygotowanie oświadczenia w sprawie dostępności`}</h2>
    <p>{`Deklarację sporządzono dnia 04.12.2020 r. na podstawie samooceny.`}</p>
    <h2>{`Informacje zwrotne i dane kontaktowe`}</h2>
    <p>{`Za rozpatrywanie uwag i wniosków odpowiada: Anna Korpak`}</p>
    <p>{`E-mail: brzekowice@gok.psary.pl`}</p>
    <p>{`Każdy ma prawo:`}</p>
    <ul>
      <li parentName="ul">{`zgłosić uwagi dotyczące dostępności cyfrowej strony lub jej elementu, `}</li>
      <li parentName="ul">{`zażądać zapewnienia dostępności cyfrowej strony lub jej elementu, `}</li>
      <li parentName="ul">{`wnioskować o udostępnienie niedostępnej informacji w innej alternatywnej formie. `}</li>
    </ul>
    <p>{`Żądanie powinno zawierać:`}</p>
    <ul>
      <li parentName="ul">{`dane osoby zgłaszającej,`}</li>
      <li parentName="ul">{`wskazanie strony lub elementu strony, której dotyczy żądanie, `}</li>
      <li parentName="ul">{`wskazanie dogodnej formy udostępnienia informacji, jeśli żądanie dotyczy udostępnienia informacji niedostępnej w formie alternatywnej.`}</li>
    </ul>
    <p>{`Rozpatrzenie zgłoszenia powinno nastąpić niezwłocznie, najpóźniej w ciągu 7 dni. Jeśli w tym terminie zapewnienie dostępności albo zapewnienie dostępu w alternatywnej formie nie jest możliwe, powinno nastąpić najdalej w ciągu 2 miesięcy od daty zgłoszenia. `}</p>
    <h2>{`Skargi i odwołania`}</h2>
    <p>{`Na niedotrzymanie tych terminów oraz na odmowę realizacji żądania można złożyć skargę do organu nadzorującego pocztą lub drogą elektroniczną na adres:`}</p>
    <ul>
      <li parentName="ul">{`Organ nadzorujący: Dyrektor Gminnego Ośrodka Kultury Gminy Psary `}</li>
      <li parentName="ul">{`Adres: ul. Zwycięstwa 2, 42-575 Gródków`}</li>
      <li parentName="ul">{`E-mail: dyrektor@gok.psary.pl`}</li>
      <li parentName="ul">{`Telefon: 32 267 22 59`}</li>
    </ul>
    <p>{`Skargę można złożyć również do `}<a parentName="p" {...{
        "href": "https://www.rpo.gov.pl/content/jak-zglosic-sie-do-rzecznika-praw-obywatelskich"
      }}>{`Rzecznika Praw Obywatelskich`}</a>{`.`}</p>
    <h2>{`Dostępność architektoniczna`}</h2>
    <p>{`Adres: Gminny Ośrodek Kultury Gminy Psary
ul Zwycięstwa 2
42-575 Gródków`}</p>
    <p>{`Do budynku Gminnego Ośrodka Kultury w Gródkowie prowadzą dwa wejścia.`}</p>
    <p>{`Przed wejściem głównym do budynku znajdują się schody oraz podjazd dla wózków inwalidzkich. `}</p>
    <p>{`Wejście od podwórka prowadzące do biur jest niedostępne dla osób z niepełnosprawnością ruchową, ponieważ do budynku prowadzą schody. Przy drzwiach znajduje się dzwonek. `}</p>
    <p>{`W budynku brak windy. `}</p>
    <p>{`Przed budynkiem wyznaczono miejsce parkingowe dla osób z niepełnosprawnością oznaczone kolorem niebieskim.`}</p>
    <p>{`Toaleta dla osób poruszających się na wózkach inwalidzkich znajduje się w holu głównym przy szatni. `}</p>
    <p>{`Do budynku można wejść z psem asystującym i psem przewodnikiem.`}</p>
    <p>{`W budynku nie ma oznaczeń w alfabecie Brajla, planów tyflograficznych, schematów dotykowych ani oznaczeń kontrastowych.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      